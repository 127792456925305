@font-face {
  font-family: ActualBold;
  font-display: auto;
  src: url("./actual-ttf/Actual_Bold.ttf") format("truetype");
}
@font-face {
  font-family: ActualLight;
  font-display: auto;
  src: url("./actual-ttf/Actual_Light.ttf") format("truetype");
}
@font-face {
  font-family: ActualMedium;
  font-display: auto;
  src: url("./actual-ttf/Actual_Medium.ttf") format("truetype");
}
@font-face {
  font-family: Actual;
  font-display: auto;
  src: url("./actual-ttf/Actual_Regular.ttf") format("truetype");
}
@font-face {
  font-family: ActualR;
  font-display: auto;
  src: url("./actual-ttf/Actual_Regular.ttf") format("truetype");
}
@font-face {
  font-family: ActualB;
  font-display: auto;
  src: url("./actual-ttf/Actual_Bold.ttf") format("truetype");
}
@font-face {
  font-family: ActualL;
  font-display: auto;
  src: url("./actual-ttf/Actual_Light.ttf") format("truetype");
}
@font-face {
  font-family: ActualM;
  font-display: auto;
  src: url("./actual-ttf/Actual_Medium.ttf") format("truetype");
}

body {
  font-family: 'Actual', 'arial', sans-serif;
}
